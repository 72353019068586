import { generateMapByOpts } from 'common/utils'
import { communityParams } from 'common/select2Params'
const ruleOps = [
  {
    text: '全部',
    value: null
  }, {
    text: '顺序',
    value: 1
  }, {
    text: '乱序',
    value: 2
  }]

const statusOps = [
  {
    text: '全部',
    value: null
  }, {
    text: '启用',
    value: 1
  }, {
    text: '关闭',
    value: 0
  }]

const ruleMap = generateMapByOpts(ruleOps)
const statusMap = generateMapByOpts(statusOps)

// 巡更类型
const patrolTypeOps = [
  {
    text: '日常巡更',
    value: 1
  },
  // {
  //   text: '临时巡更',
  //   value: 2
  // },
]
const patrolTypeMap = generateMapByOpts(patrolTypeOps)

// 巡更重复周期
const periodOps = [
  {
    text: '日',
    value: 1
  },
  {
    text: '星期',
    value: 2
  },
  {
    text: '月',
    value: 3
  },
]
const periodMap = generateMapByOpts(periodOps)

// patrolPeriodNumOps
const patrolPeriodNumOps = [
  {
    label: '一',
    value: 1
  },
  {
    label: '二',
    value: 2
  },
  {
    label: '三',
    value: 3
  },
  {
    label: '四',
    value: 4
  },
  {
    label: '五',
    value: 5
  },
  {
    label: '六',
    value: 6
  },
  {
    label: '七',
    value: 7
  },
]

const patrolPointTypeOps = function (type) {
  let arr = [];
  if (type === 1) {
    arr.push({
      text: '请选择',
      value: null
    })
  }
  if (type === 2) {
    arr.push({
      text: '全部',
      value: null
    })
  }
  arr.push({
    text: '线下巡更',
    value: 1
  })
  if (this.videoPermission) {
    arr.push({
      text: '视频远程巡更',
      value: 2
    })
  }
  return arr
}

const patrolPointTypeMap = function () {
  return generateMapByOpts(patrolPointTypeOps.apply(this, arguments))
}

const patrolWayOps = [
  {
    text: '蓝牙',
    value: 1
  },
  {
    text: '视频',
    value: 2
  },
  {
    text: '二维码',
    value: 3
  },
  {
    text: 'NFC',
    value: 4
  }
]

const patrolWayMap = generateMapByOpts(patrolWayOps)

const repetitionTaskOps = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const repetitionTaskMap = generateMapByOpts(repetitionTaskOps)

export {
  ruleOps,
  statusOps,
  ruleMap,
  statusMap,
  communityParams,
  patrolTypeOps,
  patrolTypeMap,
  patrolWayOps,
  patrolWayMap,
  periodOps,
  periodMap,
  patrolPeriodNumOps,
  patrolPointTypeOps, 
  patrolPointTypeMap,
  repetitionTaskOps,
  repetitionTaskMap
}
