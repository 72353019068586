var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patrolUpdate-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _c("v-button", { on: { click: _vm.previewPatrol } }, [
                      _vm._v("预览巡更路线"),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "巡更路线名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入选择巡更点",
                            trigger: "blur",
                          },
                        ],
                        prop: "name",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { width: 200 },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "请输入所属项目",
                            trigger: "change",
                          },
                        ],
                        prop: "communityId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { width: 200 },
                            on: { onChange: _vm.communityChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.blockOps.length > 0,
                          expression: "blockOps.length > 0",
                        },
                      ],
                      attrs: {
                        label: "所属组团",
                        rules: [
                          {
                            required: _vm.blockOps.length > 0,
                            message: "请选择所属组团",
                            trigger: "change",
                          },
                        ],
                        prop: "blockName",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.blockOps },
                        model: {
                          value: _vm.form.blockName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "blockName", $$v)
                          },
                          expression: "form.blockName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "巡更方式",
                        rules: [
                          {
                            required: true,
                            message: "请选择巡更方式",
                            trigger: "change",
                          },
                        ],
                        prop: "patrolMethod",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.patrolPointTypeOps,
                          width: _vm.width,
                        },
                        on: { change: _vm.handleMethodChange },
                        model: {
                          value: _vm.form.patrolMethod,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "patrolMethod", $$v)
                          },
                          expression: "form.patrolMethod",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择巡更点",
                        rules: _vm.pointRules,
                        prop: "pointInfo",
                      },
                    },
                    [
                      _c("v-button", {
                        attrs: {
                          text: "添加",
                          disabled: !(
                            _vm.form.communityId && _vm.form.patrolMethod
                          ),
                        },
                        on: { click: _vm.multiSelectShow },
                      }),
                      _c("span", [_vm._v("(请先选择项目和巡更方式)")]),
                      _vm.tableHeaders.length
                        ? _c("table-panel-draggable", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.pointInfo.length > 0,
                                expression: "form.pointInfo.length > 0",
                              },
                            ],
                            ref: "tablePanel1",
                            staticClass: "point-table",
                            style: { width: "900px" },
                            attrs: {
                              headers: _vm.tableHeaders,
                              tableData: _vm.form.pointInfo,
                              isMultiSelect: true,
                            },
                            on: {
                              "update:tableData": function ($event) {
                                return _vm.$set(_vm.form, "pointInfo", $event)
                              },
                              "update:table-data": function ($event) {
                                return _vm.$set(_vm.form, "pointInfo", $event)
                              },
                              handleSelectionChange: _vm.selectionChange,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "900px",
                            display: "flex",
                            "justify-content": "flex-end",
                          },
                        },
                        [
                          _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.pointInfo.length > 0,
                                expression: "form.pointInfo.length > 0",
                              },
                            ],
                            attrs: {
                              type: "danger",
                              text: "删除",
                              disabled: _vm.selectedData.length === 0,
                            },
                            on: { click: _vm.delTableData },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "路线有效期",
                        rules: _vm.dateRules,
                        prop: "effectiveDate",
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          startTime: _vm.form.effectiveDate.beginDate,
                          endTime: _vm.form.effectiveDate.endDate,
                        },
                        on: {
                          "update:startTime": function ($event) {
                            return _vm.$set(
                              _vm.form.effectiveDate,
                              "beginDate",
                              $event
                            )
                          },
                          "update:start-time": function ($event) {
                            return _vm.$set(
                              _vm.form.effectiveDate,
                              "beginDate",
                              $event
                            )
                          },
                          "update:endTime": function ($event) {
                            return _vm.$set(
                              _vm.form.effectiveDate,
                              "endDate",
                              $event
                            )
                          },
                          "update:end-time": function ($event) {
                            return _vm.$set(
                              _vm.form.effectiveDate,
                              "endDate",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "重复周期",
                        rules: [
                          {
                            required: true,
                            message: "请选择重复周期",
                            trigger: "blur",
                          },
                        ],
                        prop: "period",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.periodOps },
                        on: { change: _vm.handlePeriodChange },
                        model: {
                          value: _vm.form.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "period", $$v)
                          },
                          expression: "form.period",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.period === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "重复时间",
                            rules: [
                              {
                                required: true,
                                message: "请选择重复时间",
                                trigger: "blur",
                              },
                            ],
                            prop: "patrolPeriodNums",
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              type: "default",
                              mode: "checkbox-plus",
                              options: _vm.patrolPeriodNumOps,
                              value: _vm.form.patrolPeriodNums,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "patrolPeriodNums",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.period === 3
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "重复时间",
                            rules: [
                              {
                                required: true,
                                message: "请选择重复时间",
                                trigger: "blur",
                              },
                            ],
                            prop: "patrolPeriodNums",
                          },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "patrol_period_num" },
                            _vm._l(_vm.numList, function (item) {
                              return _c(
                                "li",
                                {
                                  key: item,
                                  class: {
                                    active: item !== 32,
                                    active: _vm.verifyClass(item),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSelectPatrolNum(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item === 32 ? "" : item) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "patrol-frequency-container" },
                    [
                      _vm._l(_vm.form.periods, function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            attrs: { label: index === 0 ? "巡更频率" : "" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "patrol-frequency" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入分钟",
                                          trigger: "blur",
                                        },
                                      ],
                                      prop: "periods." + index + ".period",
                                    },
                                  },
                                  [
                                    _c("v-input", {
                                      attrs: { width: 100 },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.periodInput(index)
                                        },
                                      },
                                      model: {
                                        value: item.period,
                                        callback: function ($$v) {
                                          _vm.$set(item, "period", $$v)
                                        },
                                        expression: "item.period",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n               分钟巡更一次，允许超时\n              "
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入间隔时间",
                                          trigger: "blur",
                                        },
                                      ],
                                      prop: "periods." + index + ".overMinute",
                                    },
                                  },
                                  [
                                    _c("v-input", {
                                      attrs: { width: 80 },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.overMinuteInput(index)
                                        },
                                      },
                                      model: {
                                        value: item.overMinute,
                                        callback: function ($$v) {
                                          _vm.$set(item, "overMinute", $$v)
                                        },
                                        expression: "item.overMinute",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(
                                  "\n              时间段\n                "
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入有效时间",
                                          trigger: "blur",
                                        },
                                      ],
                                      prop: "periods." + index + ".beginTime",
                                    },
                                  },
                                  [
                                    _c("v-timepicker", {
                                      staticClass: "timepicker",
                                      attrs: {
                                        isRange: true,
                                        startTime: item.beginTime,
                                        endTime: item.endTime,
                                        format: "HH:mm",
                                      },
                                      on: {
                                        "update:startTime": function ($event) {
                                          return _vm.$set(
                                            item,
                                            "beginTime",
                                            $event
                                          )
                                        },
                                        "update:start-time": function ($event) {
                                          return _vm.$set(
                                            item,
                                            "beginTime",
                                            $event
                                          )
                                        },
                                        "update:endTime": function ($event) {
                                          return _vm.$set(
                                            item,
                                            "endTime",
                                            $event
                                          )
                                        },
                                        "update:end-time": function ($event) {
                                          return _vm.$set(
                                            item,
                                            "endTime",
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("v-button", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.form.periods.length > 1,
                                      expression: "form.periods.length > 1",
                                    },
                                  ],
                                  staticClass: "delBtn",
                                  attrs: { type: "danger", text: "删除" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delFrequency(index)
                                    },
                                  },
                                }),
                                _c("v-button", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: index === 0,
                                      expression: "index === 0",
                                    },
                                  ],
                                  staticClass: "delBtn",
                                  attrs: { text: "新增" },
                                  on: { click: _vm.addFrequency },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            margin: "0 0px 15px 168px",
                            "font-weight": "700",
                          },
                        },
                        [
                          _vm._v(
                            "比如：60分钟巡更一次，允许超时10分钟，即巡更总时长为60分钟，前50分钟为正常，后10分钟为超时"
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm.form.patrolMethod !== 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "打卡规则",
                            rules: [
                              {
                                required: !_vm.$route.query.id,
                                message: "请选择打卡规则",
                                trigger: "change",
                              },
                            ],
                            prop: "rule",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.newRuleOps },
                            model: {
                              value: _vm.form.rule,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rule", $$v)
                              },
                              expression: "form.rule",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "提醒通知",
                            rules: [
                              {
                                required: true,
                                message: "请填写提醒通知",
                                trigger: "change",
                              },
                            ],
                            prop: "remindMinute",
                          },
                        },
                        [
                          _vm._v("\n          任务开始前"),
                          _c("v-input-number", {
                            attrs: {
                              controls: "",
                              controlsPosition: "right",
                              min: 0,
                              max: 120,
                              width: 90,
                            },
                            model: {
                              value: _vm.form.remindMinute,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remindMinute", $$v)
                              },
                              expression: "form.remindMinute",
                            },
                          }),
                          _vm._v(
                            "分钟，发送提醒，若为0，则不发送提醒\n        "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.patrolMethod !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "视频查看最短时间",
                            prop: "minWatchTime",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              controls: "",
                              controlsPosition: "right",
                              min: 0,
                              max: 120,
                              width: 90,
                            },
                            model: {
                              value: _vm.form.minWatchTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "minWatchTime", $$v)
                              },
                              expression: "form.minWatchTime",
                            },
                          }),
                          _vm._v(" 秒\n        "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "路线状态",
                        rules: [
                          {
                            required: !_vm.$route.query.id,
                            message: "请选择路线状态",
                            trigger: "change",
                          },
                        ],
                        prop: "status",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.newStatusOps },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否允许重复接受任务",
                        rules: [
                          {
                            required: true,
                            message: "请选择是否允许重复接受任务",
                            trigger: "change",
                          },
                        ],
                        prop: "allowExtraReceive",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.repetitionTaskOps },
                        model: {
                          value: _vm.form.allowExtraReceive,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "allowExtraReceive", $$v)
                          },
                          expression: "form.allowExtraReceive",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        ref: "multiSelect",
        attrs: {
          isShow: _vm.multiSelectConfig.isShow,
          backFill: _vm.multiSelectConfig.selectedData,
          searchUrl: _vm.multiSelectConfig.searchUrl,
          searchParams: _vm.multiSelectConfig.searchParams,
          extraParams: _vm.extraParams,
          isMultiSelect: true,
          responseParams: _vm.multiSelectConfig.responseParams,
          responseOtherKey: _vm.multiSelectConfig.responseOtherKey,
          headers: _vm.multiSelectConfig.headers,
          title: "巡更点",
          showAllSelectBtn: false,
        },
        on: {
          "update:isShow": function ($event) {
            return _vm.$set(_vm.multiSelectConfig, "isShow", $event)
          },
          "update:is-show": function ($event) {
            return _vm.$set(_vm.multiSelectConfig, "isShow", $event)
          },
          callback: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "巡更点名称" },
                  model: {
                    value: _vm.multiSelectConfig.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.multiSelectConfig.searchParams, "name", $$v)
                    },
                    expression: "multiSelectConfig.searchParams.name",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "设备编号" },
                  model: {
                    value: _vm.multiSelectConfig.searchParams.bluetoothNum,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiSelectConfig.searchParams,
                        "bluetoothNum",
                        $$v
                      )
                    },
                    expression: "multiSelectConfig.searchParams.bluetoothNum",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "设备位置" },
                  model: {
                    value: _vm.multiSelectConfig.searchParams.location,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.multiSelectConfig.searchParams,
                        "location",
                        $$v
                      )
                    },
                    expression: "multiSelectConfig.searchParams.location",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "mobile-preview",
        {
          attrs: { isShow: _vm.isPreviewShow },
          on: {
            "update:isShow": function ($event) {
              _vm.isPreviewShow = $event
            },
            "update:is-show": function ($event) {
              _vm.isPreviewShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "patrol-timeline",
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.form.pointInfo, function (activity, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: {
                        timestamp: _vm.timestamp,
                        "hide-timestamp": true,
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "巡更点" +
                            _vm._s(index + 1) +
                            "：" +
                            _vm._s(activity.name)
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            padding: "10px 0",
                            "hide-timestampdisplay": "block",
                          },
                        },
                        [_vm._v("巡更要点：")]
                      ),
                      _vm._l(
                        _vm.patrolContents[index],
                        function (content, idx) {
                          return _c("li", { key: idx }, [
                            _vm._v(_vm._s(idx + 1) + "、" + _vm._s(content)),
                          ])
                        }
                      ),
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }