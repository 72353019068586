// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/route`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/route/export`
// 保存巡更路线管理
const saveRouteUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/route`
// 获取巡更路线详情
const getRouteUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/route/`
// 查询巡更点
const getPointUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/point`
// 获取组团列表
// const getBlockUrl = `${API_CONFIG.baseURL}serverRoomAction!queryBlockNameFromCommunity.action`
// 获取巡更要点
const getPatrolContentURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/route/preview`
const deleteURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/deleteElectronicPatrolRoute`
export {
  getListURL,
  exportListURL,
  saveRouteUrl,
  getRouteUrl,
  getPointUrl,
  // getBlockUrl,
  getPatrolContentURL,
  deleteURL
}
