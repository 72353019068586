<template>
  <div class="patrolUpdate-container">
    <form-panel ref="formPanel" :form="form" v-bind='submitConfig' :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore">
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item label="巡更路线名称" :rules="[{ required:true, message: '请输入选择巡更点', trigger: 'blur' }]" prop="name">
            <v-input v-model="form.name" :width="200"></v-input>
          </el-form-item>
          <el-form-item label="所属项目" :rules="[{ required:true, message: '请输入所属项目', trigger: 'change' }]" prop="communityId">
            <v-select2  v-model="form.communityId" v-bind="communityParams" @onChange='communityChange' :width="200"></v-select2>
          </el-form-item>
          <el-form-item label="所属组团" v-show='blockOps.length > 0' :rules="[{ required:blockOps.length > 0, message: '请选择所属组团', trigger: 'change' }]" prop="blockName">
            <v-select v-model="form.blockName" :options="blockOps" ></v-select>
          </el-form-item>
          <el-form-item label="巡更方式" :rules="[{ required:true, message: '请选择巡更方式', trigger: 'change' }]" prop="patrolMethod">
            <v-select v-model="form.patrolMethod" :options="patrolPointTypeOps" :width="width" @change="handleMethodChange"></v-select>
          </el-form-item>
          <!-- <el-form-item label="巡更类型" :rules="[{ required:true, message: '请选择巡更类型', trigger: 'change' }]" prop="patrolType">
            <v-select v-model="form.patrolType" :options="patrolTypeOps" :width="width"></v-select>
          </el-form-item> -->
          <el-form-item label="选择巡更点" :rules='pointRules' prop="pointInfo">
            <v-button text="添加" @click="multiSelectShow" :disabled="!(form.communityId && form.patrolMethod)"></v-button><span>(请先选择项目和巡更方式)</span>
            <!-- <table-panel ref="tablePanel" :style="{width:'900px'}" v-if="tableHeaders.length" :headers="tableHeaders" :tableData.sync="form.pointInfo" :isMultiSelect="true" :hasOperateColumn="false" @handleSelectionChange='selectionChange' v-show='form.pointInfo.length > 0' class='point-table'>
            </table-panel> -->
            <table-panel-draggable
              ref="tablePanel1"
              :style="{width:'900px'}"
              v-if="tableHeaders.length"
              :headers="tableHeaders"
              :tableData.sync="form.pointInfo"
              :isMultiSelect="true"
              @handleSelectionChange='selectionChange'
              v-show='form.pointInfo.length > 0'
              class='point-table'>
            </table-panel-draggable>
            <div style='width:900px;display:flex;justify-content: flex-end;'>
              <v-button type="danger" text="删除" @click="delTableData" :disabled="selectedData.length === 0" v-show='form.pointInfo.length > 0'></v-button>
            </div>
          </el-form-item>
          <el-form-item label="路线有效期" :rules="dateRules" prop="effectiveDate">
               <v-datepicker :startTime.sync="form.effectiveDate.beginDate" :endTime.sync="form.effectiveDate.endDate"></v-datepicker>
          </el-form-item>
          <el-form-item label="重复周期" :rules="[{ required:true, message: '请选择重复周期', trigger: 'blur' }]" prop="period">
            <v-select v-model="form.period" :options="periodOps" @change="handlePeriodChange"></v-select>
          </el-form-item>
          <el-form-item v-if="form.period === 2" label="重复时间" :rules="[{ required:true, message: '请选择重复时间', trigger: 'blur' }]" prop="patrolPeriodNums">
             <checkbox-plus type="default" mode="checkbox-plus" :options="patrolPeriodNumOps" :value.sync="form.patrolPeriodNums"/>
          </el-form-item>
          <el-form-item v-if="form.period === 3" label="重复时间" :rules="[{ required:true, message: '请选择重复时间', trigger: 'blur' }]" prop="patrolPeriodNums">
            <ul class="patrol_period_num">
              <li
                :class="{'active': item !== 32, 'active': verifyClass(item)}"
                v-for="item in numList"
                :key="item"
                @click="onSelectPatrolNum(item)"
              >
                {{item === 32 ? '' : item}}
              </li>
            </ul>
          </el-form-item>
          <div class="patrol-frequency-container">
            <el-form-item :label="index === 0 ? '巡更频率' : ''" v-for="(item,index) in form.periods" :key="index">
              <div  class="patrol-frequency">
                <el-form-item :rules="[{ required:true, message: '请输入分钟', trigger: 'blur' }]" :prop="'periods.' + index +'.period'">
                  <v-input v-model="item.period" :width="100" @keyup.native="periodInput(index)"></v-input>
                </el-form-item>
                 分钟巡更一次，允许超时
                <el-form-item :rules="[{ required:true, message: '请输入间隔时间', trigger: 'blur' }]" :prop="'periods.' + index +'.overMinute'">
                  <v-input v-model="item.overMinute" :width="80"  @keyup.native="overMinuteInput(index)"></v-input>
                </el-form-item>
                时间段
                  <el-form-item :rules="[{ required:true, message: '请输入有效时间', trigger: 'blur' }]" :prop="'periods.' + index +'.beginTime'">
                      <v-timepicker :isRange="true" :startTime.sync="item.beginTime" :endTime.sync="item.endTime" format="HH:mm" class="timepicker"/>
                  </el-form-item>
                <v-button type="danger" text="删除" class='delBtn' @click="delFrequency(index)"  v-show="form.periods.length > 1"></v-button>
                <v-button text="新增" @click="addFrequency" class='delBtn'  v-show="index === 0"></v-button>
               </div>
            </el-form-item>
            <div style="margin:0 0px 15px 168px;font-weight:700">比如：60分钟巡更一次，允许超时10分钟，即巡更总时长为60分钟，前50分钟为正常，后10分钟为超时</div>
          </div>

          <!-- 暂无后端接口 -->
          <!-- <div class="new-container">
            <el-form-item label="拍照比例" :rules="[{ required:true, message: '请选择拍照比例', trigger: 'change' }]" prop="photoProportion">
              <el-input-number v-model="form.photoProportion" :min="0" :max='100' :step="10"></el-input-number> %
            </el-form-item>
            <div class="tips">可设置0-100，设置0代表不需要拍照；所有巡更点的随机拍照比例</div>
          </div>

          <div class="new-container">
            <el-form-item label="单任务拍照数量" :rules="[{ required:true, message: '请选择单任务拍照数量', trigger: 'change' }]" prop="singleMissionPhotoNum">
              <el-input-number :disabled="disabledSingleMissionPhotoNum" v-model="form.singleMissionPhotoNum" :min="0" :max='4' :step="1"></el-input-number>
            </el-form-item>
            <div class="tips margin">可设置0-4，设置1代表单次需拍照1次</div>
          </div> -->
        
          <el-form-item label="打卡规则" v-if="form.patrolMethod !== 2" :rules="[{ required: !$route.query.id, message: '请选择打卡规则', trigger: 'change' }]" prop="rule">
            <v-select v-model="form.rule" :options="newRuleOps"></v-select>
          </el-form-item>
          <el-form-item v-if="false" label="提醒通知" :rules="[{ required:true, message: '请填写提醒通知', trigger: 'change' }]" prop="remindMinute">
            任务开始前<v-input-number v-model="form.remindMinute" controls controlsPosition="right" :min="0" :max="120" :width="90"></v-input-number>分钟，发送提醒，若为0，则不发送提醒
          </el-form-item>
          <el-form-item v-if="form.patrolMethod !== 1" label="视频查看最短时间" prop="minWatchTime">
            <v-input-number v-model="form.minWatchTime" controls controlsPosition="right" :min="0" :max="120" :width="90"></v-input-number> 秒
          </el-form-item>
          <el-form-item label="路线状态"  :rules="[{ required: !$route.query.id, message: '请选择路线状态', trigger: 'change' }]" prop="status">
            <v-select v-model="form.status" :options="newStatusOps"></v-select>
          </el-form-item>
          <el-form-item label="是否允许重复接受任务"  :rules="[{ required: true, message: '请选择是否允许重复接受任务', trigger: 'change' }]" prop="allowExtraReceive">
            <v-select v-model="form.allowExtraReceive" :options="repetitionTaskOps"></v-select>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
         <v-button @click="previewPatrol">预览巡更路线</v-button>
      </template>
    </form-panel>
     <multi-select ref="multiSelect" :isShow.sync="multiSelectConfig.isShow" :backFill="multiSelectConfig.selectedData" :searchUrl="multiSelectConfig.searchUrl" :searchParams="multiSelectConfig.searchParams" :extraParams="extraParams" :isMultiSelect="true" :responseParams="multiSelectConfig.responseParams" :responseOtherKey="multiSelectConfig.responseOtherKey" :headers="multiSelectConfig.headers" title="巡更点" :showAllSelectBtn="false" @callback="success">
              <template #searchSlot>
                <v-input v-model="multiSelectConfig.searchParams.name" label="巡更点名称"></v-input>
                <v-input v-model="multiSelectConfig.searchParams.bluetoothNum" label="设备编号"></v-input>
                <v-input v-model="multiSelectConfig.searchParams.location" label="设备位置"></v-input>
              </template>
     </multi-select>
     <mobile-preview :isShow.sync="isPreviewShow">
         <div slot="content" class="patrol-timeline">
          <el-timeline>
            <el-timeline-item v-for="(activity, index) in form.pointInfo" :key="index" :timestamp="timestamp" :hide-timestamp="true">
              <span>巡更点{{index + 1}}：{{activity.name}}</span>
              <span style="padding:10px 0;hide-timestampdisplay:block">巡更要点：</span>
               <li v-for="(content, idx) in patrolContents[index]" :key="idx">{{ idx+1 }}、{{ content }}</li>
            </el-timeline-item>
          </el-timeline>
         </div>
     </mobile-preview>
  </div>
</template>

<script>
import {
  saveRouteUrl,
  getRouteUrl,
  getPointUrl,
  // getBlockUrl,
  getPatrolContentURL } from './api'
import { ruleOps, statusOps, statusMap,  patrolTypeOps, periodOps, patrolPeriodNumOps, patrolPointTypeOps, patrolWayMap,repetitionTaskOps } from './map'
import { MultiSelect, Col2Block, Col2Detail, TablePanelDraggable, TablePanel, MobilePreview, CheckboxPlus } from 'components/bussiness'
import { vCheckbox } from 'components/control'
import { createNumberVNode } from 'common/vdom'
import Vue from 'vue'
import { Timeline, TimelineItem, InputNumber } from 'element-ui'
import moment from 'moment'
import draggable from 'vuedraggable'
import { normalCommunityParams } from 'common/select2Params'
import { select2BlockURL } from 'common/api'
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(InputNumber)
moment.locale('zh-cn')

export default {
  name: 'routeUpdateForm',
  components: {
    MultiSelect,
    TablePanel,
    TablePanelDraggable,
    Col2Block,
    Col2Detail,
    MobilePreview,
    vCheckbox,
    draggable,
    CheckboxPlus,
  },
  data () {
    // 自定义校验巡更点
    let validatePoints = (rule, value, callback) => {
      if (value.length === 0) {
        return callback(new Error('请添加巡更点!!'))
      } else {
        return callback()
      }
    }
    let validateDate = (rule, value, callback) => {
      if (!value.beginDate || !value.endDate) {
        return callback(new Error('请添加路线有效期!!'))
      } else {
        return callback()
      }
    }
    return {
      width: 200,
      patrolTypeOps,
      repetitionTaskOps,
      periodOps,
      patrolPeriodNumOps,
      submitUrl: saveRouteUrl,
      submitConfig: {
        // submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: this.$route.query.id ? 'put' : 'post'
      },
      form: {
        id: 0,
        name: '',
        effectiveDate: {
          beginDate: '',
          endDate: ''
        },
        communityId: '',
        blockName: undefined,
        // patrolType: undefined,
        patrolMethod: undefined,
        period: 1,
        patrolPeriodNums: [],
        rule: 1,
        status: 1,
        allowExtraReceive: 0,
        remindMinute: 0,
        minWatchTime: 5,
        pointInfo: [],
        periods: [{ beginTime: '', endTime: '', overMinute: '', period: '' }],
        // photoProportion: 0, // 拍照比例
        // singleMissionPhotoNum: 0, // 单任务拍照数量
      },
      pointRules: [{ required: true, validator: validatePoints, trigger: 'blur' }],
      dateRules: [{ required: true, validator: validateDate, trigger: 'blur' }],
      newStatusOps: [],
      newRuleOps: [],
      communityParams: normalCommunityParams,
      blockOps: [],
      multiSelectConfig: {
        isShow: false,
        searchUrl: getPointUrl,
        searchParams: {
          name: '',
          bluetoothNum: '',
          location: ''
        },
        extraParams: {

        },
        responseParams: {
          id: 'id',
          name: 'name'
        },
        responseOtherKey: ['parentName', 'categoryName', 'bluetoothNum', 'location', 'patrolPointType'],
        selectedData: [],
        headers: [
          {
            prop: 'name',
            label: '巡更点名称'
          },
          {
            prop: 'parentName',
            label: '一级分类'
          },
          {
            prop: 'categoryName',
            label: '二级分类'
          },
          {
            prop: 'patrolPointType',
            label: '设备类型',
            formatter: (row) => patrolWayMap[row.patrolPointType] || '未知'
          },
          {
            prop: 'bluetoothNum',
            label: '设备编号'
          },
          {
            prop: 'location',
            label: '设备位置'
          },
          {
            prop: 'communityName',
            label: '所属项目'
          },
          {
            prop: 'blockName',
            label: '所属组团'
          },
          {
            prop: 'bluetoothStatus',
            label: '设备状态',
            formatter: (row) => statusMap[row.bluetoothStatus]
          }
        ]
      },
      tableHeaders: [
        {
          prop: 'name',
          label: '巡更点名称'
        },
        {
          prop: 'parentName',
          label: '一级分类'
        },
        {
          prop: 'categoryName',
          label: '二级分类'
        },
        {
          prop: '',
          label: '设备类型',
          formatter: (row) => {
            return patrolWayMap[row.patrolPointType] || '未知'
          }
        },
        {
          prop: 'bluetoothNum',
          label: '设备编号'
        },
        {
          prop: 'location',
          label: '设备位置'
        },
        // {
        //   prop: 'sort',
        //   label: '排序',
        //   width: 200,
        //   formatter: (row, prop) => {
        //     return createNumberVNode(this, row, prop)
        //   }
        // }
      ],
      tableData: [],
      selectedData: [],
      isPreviewShow: false,
      timestamp: '',
      patrolContents: [],
      patrolPeriodNumList: [],
      patrolMethodChange: false,
      prevPatrolMethod: null,
      onOff: {
        communityId: false,
        patrolMethod: false
      },
      periodChange: false
    }
  },
  computed: {
    numList () {
      let num = 0
      let arr = []
      while (num < 32) {
        num++
        arr.push(num)
      }
      return arr
    },
    extraParams () {
      this.prevPatrolMethod = this.form.patrolMethod
      // 如果选择线下巡更 deviceType 不传  patrolMethod ：2 视频巡更
      let obj = {
        communityId: this.form.communityId || undefined
      }
      if (this.form.patrolMethod == 2) {
        obj.deviceType = this.form.patrolMethod
      }
      return obj
    },
    videoPermission () {
      return this.$store.getters.getPermission('videoControl')
    },
    patrolPointTypeOps () {
      return patrolPointTypeOps.call(this)
    },
    // disabledSingleMissionPhotoNum() {
    //   return this.form.photoProportion === 0 ? true : false;
    // }
  },
  created () {
    this.newRuleOps = [...ruleOps]
    this.newRuleOps.shift()
    this.newStatusOps = [...statusOps]
    this.newStatusOps.shift()
    this.timestamp = moment().format('YYYY-MM-DD h:mm:ss')
    this.periodChange = true
  },
  mounted () {
    this.onOff = {
      communityId: false,
      patrolMethod: false
    }
    if (this.$route.query.id) {
      this.onOff = {
        communityId: true,
        patrolMethod: true
      }
      this.getDetailData(this.$route.query.id)
    } else {
      // 新增
      this.form.patrolMethod = this.$route.query.type || 1
    }
  },
  methods: {
    // 选择园区 获取组团
    communityChange (selected, isCb) {
      if (this.onOff.communityId) {
        this.onOff.communityId = false
      } else {
        this.multiSelectConfig.selectedData = []
      }
      
      if (selected) {
        this.form.communityId = selected.id
        if (!isCb) {
          this.form.blockName = undefined
          // this.multiSelectConfig.extraParams = {}
          this.form.pointInfo = []
        }
      }
      if (this.form.communityId) {
        let _this_ = this
        // 搜索巡更点添加筛选条件
        // _this_.multiSelectConfig.extraParams = {
        //   communityId: _this_.form.communityId
        // }
        let params = { communityId: _this_.form.communityId }
        this.$axios.get(select2BlockURL, { params }).then(res => {
          if (res.status === 100) {
            let rdata = res.data
            _this_.blockOps = []
            if (rdata.length) {
              rdata.forEach(item => {
                let obj = {}
                obj.value = item.name
                obj.text = item.name
                _this_.blockOps.push(obj)
              })
            } else {
              _this_.form.blockName = undefined
            }
          }
        })
      } else {
        this.blockOps = []
        this.form.blockName = undefined
        // this.multiSelectConfig.extraParams = {}
        this.form.pointInfo = []
      }
    },
    // period输入控制
    periodInput (index) {
      let item = this.form.periods[index]
      item.period = item.period.replace(/\D/g, '')
      if (item.period > 10080) {
        item.period = 10080
      }
    },
    // overMinute输入控制
    overMinuteInput (index) {
      let item = this.form.periods[index]
      item.overMinute = item.overMinute.replace(/\D/g, '')
      if (item.overMinute > 30) {
        item.overMinute = 30
      }
    },
    // 新增巡更频率
    addFrequency () {
      if (this.form.periods.length >= 10) {
        this.$alert('最多只能添加10个时间段')
      } else {
        this.form.periods.push({ beginTime: '', endTime: '', overMinute: '', period: '' })
      }
    },
    // 删除巡更频率
    delFrequency (index) {
      console.log(this.form.periods)
      this.form.periods.splice(index, 1)
    },
    // 获取详情数据
    getDetailData (id) {
      let _this_ = this
      this.$axios.get(getRouteUrl + id).then(res => {
        if (res.status === 100) {
          let rdata = res.data
          if (rdata) {
            if (rdata.pointInfo.length) {
              rdata.pointInfo.map(item => {
                item.name = item.text
                item.sortComponent = {
                  component: true,
                  componentName: 'sortCom'
                }
              })
              _this_.tableData = rdata.pointInfo
              _this_.multiSelectConfig.selectedData = rdata.pointInfo
            }
            rdata.blockName = rdata.blockName ? rdata.blockName : undefined
            let patrolPeriodNums = rdata.patrolPeriodNums.split(',')
            rdata.patrolPeriodNums =  patrolPeriodNums.map((res)=> Number(res))
            _this_.form = Object.assign(_this_.form, rdata)
            _this_.form.effectiveDate = {
              beginDate: rdata.beginDate,
              endDate: rdata.endDate
            }
            console.log( _this_.form)
          }
        }
      })
    },
    // 多选框
    multiSelectShow () {
      this.multiSelectConfig.isShow = true
    },
    success (data) {
      this.tableData = data
      this.multiSelectConfig.selectedData = data
      this.form.pointInfo = data
      console.log(data)
    },
    // table勾选
    selectionChange (selection) {
      this.selectedData = [...selection]
    },
    // 删除勾选的table数据
    delTableData () {
      if (this.selectedData.length) {
        let _this_ = this
        this.selectedData.forEach(selection => {
          _this_.form.pointInfo.splice(_this_.form.pointInfo.findIndex(item => item.id === selection.id), 1)
        })
      }
    },
    // 保存前数据处理
    submitBefore (data) {
      let points = []
      if (this.form.pointInfo.length) {
        this.form.pointInfo.forEach((item, index) => {
          let obj = {}
          obj.pointId = item.id
          obj.sort = index
          points.push(obj)
        })
      }
      data.beginDate = data.effectiveDate.beginDate
      data.endDate = data.effectiveDate.endDate
      // 视频方式下不传查看视频最短时间
      if (this.form.patrolMethod === 1) {
        delete data.minWatchTime
      }
      delete data.pointInfo
      delete data.effectiveDate
      data.points = points
      return true
    },
    // 预览巡更路线
    previewPatrol () {
      let _this_ = this
      let postData = this.form.pointInfo.map(item => item.id).join(',')
      if (!postData) {
        this.$alert('请选择巡更点')
        return
      }
      this.$axios.get(`${getPatrolContentURL}?pointIds=${postData}`).then(res => {
        if (res.status === 100) {
          _this_.patrolContents = res.data
          _this_.isPreviewShow = true
        }
      })
    },
    // 验证当重复周期为月：是否选中
    verifyClass (num) {
      let verifyBlen = false
      if (this.form.patrolPeriodNums.indexOf(num) !== -1 && num !== 32) {
        verifyBlen = true
      }
      return verifyBlen
    },
    // 选择重复时间
    onSelectPatrolNum (num) {
      let index = this.form.patrolPeriodNums.indexOf(num);
      if (index !== -1) {
        this.form.patrolPeriodNums.splice(index, 1)
      } else {
        this.form.patrolPeriodNums.push(num)
      }
    },
    handleMethodChange () {
      if (this.onOff.patrolMethod) {
        this.onOff.patrolMethod = false
        return
      }
      this.form.pointInfo = []
      this.multiSelectConfig.selectedData = []
      // if (this.form.patrolMethod == 1) {
      //   this.multiSelectConfig.searchUrl = getPointUrl+'?deviceType=1'
      // } else {
      //   this.multiSelectConfig.searchUrl = getPointUrl
      // }
    },
    // 重复周期修改初始化
    handlePeriodChange () {
      if (!this.periodChange) {
        this.form.patrolPeriodNums = []
      }
      this.periodChange = false
    }
  }
}
</script>

<style lang="scss" scoped>
.patrolUpdate-container {
  .point-table{
    margin:10px 0;
  }
  .patrol-frequency-container{
    width: 100%;
    .patrol-frequency{
      display: inline-block;
      .el-form-item{
         display: inline-block;
      }
      .timepicker{
        display: inline-block;
        // width:250px;
      }
      .delBtn{
        margin-left:8px
      }
    }
  }
  ::v-deep.checkbox-plus-wrapper {
    .check-item {
      margin-top: 0;
      .el-checkbox__inner {
        vertical-align: middle
      }
    }
  }

  .new-container {
    display: flex;

    .tips {
      font-weight:700;
      margin-left: 30px;
      padding-top: 6px;
    }

    .margin {
      margin-left: 46px;
    }
  }
  
  .patrol-timeline{
    margin: 15px;
  }
  .patrol_period_num {
    display: flex;
    flex-wrap: wrap;
    width: 409px;
    box-sizing: border-box;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    li {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
      cursor: pointer;
    }
    li.active {
      color: #fff;
      background: rgb(105, 104, 104);
    }
  }
  ::v-deep .el-form-item__label{
    width: 178px !important;
  }
}
</style>
